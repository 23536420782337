import {
    Component,
    DestroyRef,
    OnDestroy,
    OnInit,
    inject
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
    APP_CONFIG_TOKEN,
    AppConfigModel,
    AuthService
} from '@quipex/shared/data';
import { BehaviorSubject, Observable } from 'rxjs';

import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ConfirmationDialogComponent, DialogComponent } from '@quipex/shared/components';
import { SessionIdleService } from './services/session-idle.service';

@Component({
    selector: 'qpx-root',
    standalone: true,
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    imports: [
        CommonModule,
        RouterModule,
        ConfirmationDialogComponent,
        DialogComponent
    ]
})
export class AppComponent implements OnInit, OnDestroy {
    sessionTimeoutSeconds$: Observable<number>;

    private _sessionTimeoutSecondsSubject: BehaviorSubject<number>;

    private readonly appConfig: AppConfigModel = inject(APP_CONFIG_TOKEN);
    private readonly destroyRef = inject(DestroyRef);
    private readonly authService = inject(AuthService);
    private readonly sessionIdleService = inject(SessionIdleService);

    constructor() {
        this._sessionTimeoutSecondsSubject = new BehaviorSubject<number>(
            this.appConfig.sessionConfig.idleTimeoutSeconds
        );
        this.sessionTimeoutSeconds$ =
            this._sessionTimeoutSecondsSubject.asObservable();
    }

    ngOnInit(): void {
        this.sessionIdleService.startIdleProcess();

        // When a user is idle for a set amount of time
        this.sessionIdleService.sessionIdled$
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: () => this.logout()
            });

        this.sessionIdleService.sessionActivated$
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: () =>
                    this._sessionTimeoutSecondsSubject.next(
                        this.appConfig.sessionConfig.idleTimeoutSeconds
                    )
            });
    }

    // Unsubscribe to all events when the component is destroyed to prevent memory leaks
    ngOnDestroy() {
        // Unsubscribe to auth service events
        this.authService.destroying$.next(undefined);
        this.authService.destroying$.complete();
    }

    login(): void {
        this.authService.login();
    }

    logout(): void {
        this.authService.logout();
    }

}
